import { React, useEffect, useState } from "react";
// import footerLogo from "../assets/images/footer-logo.webp";
import footerLogo from "../assets/images/footer-logo.webp";
import carImg from "../assets/images/car.webp";
import frcarImg from "../assets/images/frcar.webp";
import { useNavigate, useLocation } from "react-router-dom";
import Env from "../constants/Env";
import { Helmet } from "react-helmet";
import TranslateComponent from "../constants/TranslateComponent";
import ApiService from "../constants/ApiService";
import { toast } from "react-toastify";


function Footer({ siteData, isShowLoader }) {
  //define the state
  const navigate = useNavigate();
  let location = useLocation();
  let [currentPath, setCurrentPath] = useState("");
  let [isShowFooterImage, setIsShowFooterImage] = useState(true);
  let [siteInfo, setSiteInfo] = useState({});

  const [formData,setFormData]=useState({
    email:""
  });

 const handleEmailChange = (e) => {
  setFormData({
    ...formData, // Keep any other form data if present
    email: e.target.value // Update only the email field
  });
};

  const { postDataNew } = ApiService();


  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    isShowLoader(true);
    if (path !== null && path !== "") {
      navigate(path);
    }
  };

   let subscribeNewsletter = async () => {
     try {
       let dataInfo = await postDataNew("newsletter-submit", {
         email: formData.email,
       });
       console.log("dataInfo", dataInfo);

       if (dataInfo.responseCode === 1) {
         toast.success("Subscribed to Newsletter Successfully");
       } else if (dataInfo.responseCode === 2) {
         toast.info("Email already added to subscription");
       } else {
         toast.error(dataInfo.responseMessage);
       }

       // Reset email field after processing
       setFormData({
         ...formData,
         email: "",
       });
     } catch (error) {
       console.error("Error subscribing to newsletter:", error);
       toast.error("An error occurred while subscribing to the newsletter.");
     }
   };




  useEffect(() => {
    let pathName = location.pathname;

    if (pathName.split("/")[1] == "inventory-details") {
      pathName = "/inventory-details";
    }

    setCurrentPath(pathName);
    setIsShowFooterImage(true);

    if (
      location.pathname == "/buy-a-car" ||
      location.pathname == "/new-car-loan" ||
      location.pathname == "/cash-against-car" ||
      location.pathname == "/pre-owned-car-finance" ||
      location.pathname == "/wealth-management-services" ||
      location.pathname == "/loan-against-car" ||
      location.pathname == "/new-car-finance" ||
      location.pathname == "/inventory-for-car-dealers" ||
      location.pathname == "/inventory-for-customers" ||
      pathName == "/inventory-details" ||
      pathName == "/sell-a-car" ||
      pathName == "/insurance" ||
      pathName == "/inventory-details" ||
      pathName == "/car-services" ||
      pathName == "/privacy-policy" ||
      pathName == "/terms-and-conditions" ||
      pathName == "/thank-you" ||
      pathName == "/events" ||
      pathName == "/gallery"
    ) {
      setIsShowFooterImage(false);
    }
  }, [location]);

  useEffect(() => {
    setSiteInfo(siteData);
  }, [siteData]);

  return (
    <section class="footer_section">
      <div class="container">
        <picture>
          <source srcSet={siteInfo?.footer_logo} type="image/webp" />
          <source srcSet={siteInfo?.footer_logo} type="image/jpeg" />
          <Helmet>
            <link
              rel="preload"
              href={siteInfo?.footer_logo ? siteInfo?.footer_logo : footerLogo}
              as="image"
              type="image/webp"
              fetchpriority="high"
            />
          </Helmet>
          <img
            onClick={(e) => changeRoute(e, "/")}
            src={siteInfo?.footer_logo ? siteInfo?.footer_logo : footerLogo}
            class="flogo"
            loading="eager"
            fetchpriority="high"
            // loading="lazy"
            // fetchpriority="low"
            alt="OtoBix"
            width={216}
            height={215}
            // style={{
            //   width:"216px",
            //   height:"215px"
            //  }}
            // width={150}
            // height={150}
          />
        </picture>
        {currentPath == "/about-us" ? (
          <picture>
            <source srcSet={frcarImg} type="image/webp" />
            <source srcSet={frcarImg} type="image/jpeg" />
            <Helmet>
              <link
                rel="preload"
                href={frcarImg}
                as="image"
                type="image/webp"
                fetchpriority="high"
              />
            </Helmet>

            <img
              src={frcarImg}
              class="fcar Rcar"
              loading="eager"
              fetchpriority="high"
              // loading="lazy"
              // fetchpriority="low"
              alt="OtoBix"
              width={513}
              height={301}
            />
          </picture>
        ) : isShowFooterImage == true ? (
          <picture>
            <source srcSet={carImg} type="image/webp" />
            <source srcSet={carImg} type="image/jpeg" />
            <Helmet>
              <link
                rel="preload"
                href={carImg}
                as="image"
                type="image/webp"
                fetchpriority="high"
              />
            </Helmet>
            <img
              src={carImg}
              class="fcar"
              // loading="lazy"
              loading="eager"
              fetchpriority="high"
              // fetchpriority="low"
              alt="OtoBix"
              width={405}
              height={301}
            />
          </picture>
        ) : (
          <></>
        )}

        <div class="row">
          <div class="col-lg-12 col-md-12 d-flex justify-content-center">
            <div class="footer_contact d-flex">
              <ul class="d-flex faddress">
                <li class="ft_address">
                  <span class="icon icon-Vector-19"></span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: siteInfo?.site_address
                        ? siteInfo?.site_address
                        : "",
                    }}
                  ></span>
                </li>
                <li class="ft_phone">
                  <span class="icon icon-Vector-20"></span>
                  <a href={"tel:" + siteInfo?.site_phone_number}>
                    {siteInfo?.site_phone_number}
                  </a>
                </li>
                <li class="ft_email">
                  <span class="icon icon-Vector-18"></span>
                  <a href={"mailto:" + siteInfo?.site_email}>
                    {siteInfo?.site_email}
                  </a>
                </li>
              </ul>
              <ul class="fsocial">
                <li class="social_media">
                  <a
                    href={siteInfo?.facebook_link}
                    aria-label="Facebook"
                    target="_blank"
                  >
                    <span class="icon-Vector-22"></span>
                  </a>
                  <a
                    href={siteInfo?.twitter_link}
                    aria-label="Twitter"
                    target="_blank"
                  >
                    <span class="icon-Vector-21"></span>
                  </a>
                  <a
                    href={siteInfo?.instagram_link}
                    aria-label="Instagram"
                    target="_blank"
                  >
                    <span class="icon-Vector-23"></span>
                  </a>
                  {/* <a
                    href="https://wa.me/919088822999"
                    aria-label="whatsapp"
                    target="_blank"
                  >
                    <span class="icon-whatsapp"></span>
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="footer_menu">
              <ul class="d-flex">
                <li>
                  <a href="" onClick={(e) => changeRoute(e, "/")}>
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/about-us"
                    onClick={(e) => changeRoute(e, "/about-us")}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/sell-a-car"
                    onClick={(e) => changeRoute(e, "/sell-a-car")}
                  >
                    Sell A Car
                  </a>
                </li>
                <li>
                  <a
                    href="/buy-a-car"
                    onClick={(e) => changeRoute(e, "/buy-a-car")}
                  >
                    Buy A Car
                  </a>
                </li>
                <li>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    Finance
                  </a>
                  <ul class="fsub-menu">
                    <li class="">
                      <a
                        href="/new-car-finance"
                        onClick={(e) => changeRoute(e, "/new-car-finance")}
                      >
                        New Car Finance
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/pre-owned-car-finance"
                        onClick={(e) =>
                          changeRoute(e, "/pre-owned-car-finance")
                        }
                      >
                        Pre - Owned Car Finance
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/loan-against-car"
                        onClick={(e) => changeRoute(e, "/loan-against-car")}
                      >
                        Loan Against Car
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="/insurance"
                    onClick={(e) => changeRoute(e, "/insurance")}
                  >
                    Insurance
                  </a>
                </li>
                <li>
                  <a
                    href="/wealth-management-services"
                    onClick={(e) =>
                      changeRoute(e, "/wealth-management-services")
                    }
                  >
                    WMS
                  </a>
                </li>
                <li>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    inventory
                  </a>
                  <ul class="fsub-menu">
                    <li class="">
                      <a
                        href="/inventory-for-customers"
                        onClick={(e) =>
                          changeRoute(e, "/inventory-for-customers")
                        }
                      >
                        For Customers
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/inventory-for-car-dealers"
                        onClick={(e) =>
                          changeRoute(e, "/inventory-for-car-dealers")
                        }
                      >
                        For Car Dealers
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <a
                    href="/car-services"
                    onClick={(e) => changeRoute(e, "/car-services")}
                  >
                    Car Services
                  </a>
                </li> */}
                <li>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    Car Services
                  </a>
                  <ul class="fsub-menu">
                    <li class="">
                      <a
                        href="/car-repair"
                        onClick={(e) => changeRoute(e, "/car-repair")}
                      >
                        Car Repair
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/road-side-assistance"
                        onClick={(e) => changeRoute(e, "/road-side-assistance")}
                      >
                        Road Side Assistance (RSA)
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/warranty"
                        onClick={(e) => changeRoute(e, "/warranty")}
                      >
                        Warranty
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/car-service-booking-status"
                        onClick={(e) =>
                          changeRoute(e, "/car-service-booking-status")
                        }
                      >
                        Track The Request
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="/contact-us"
                    onClick={(e) => changeRoute(e, "/contact-us")}
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="/faqs" onClick={(e) => changeRoute(e, "/faqs")}>
                    FAQs
                  </a>
                </li>
                <li>
                  <a href="/events" onClick={(e) => changeRoute(e, "/events")}>
                    Events
                  </a>
                </li>
                <li>
                  <a
                    href="/gallery"
                    onClick={(e) => changeRoute(e, "/gallery")}
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a href={Env.sitePortalBaseUrl + "/blog"} target="_blank">
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-and-conditions"
                    onClick={(e) => changeRoute(e, "/terms-and-conditions")}
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                    onClick={(e) => changeRoute(e, "/privacy-policy")}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="subscription">
          <div className="subscribe">
            <p className="subscribe__copy">
              Subscribe to keep up with fresh news and exciting updates. We
              promise not to spam you!
            </p>
            <div className="form">
              <input
                type="email"
                name="email"
                onChange={handleEmailChange}
                value={formData.email}
                className="form__email"
                placeholder="Enter your email address"
              />
              <button className="form__button" onClick={subscribeNewsletter}>
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="ft_bottom_section">
              <TranslateComponent />
              <p>
                Copyright © {new Date().getFullYear()} | {siteInfo?.appname}.
                All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
