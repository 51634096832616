import axios from "axios";
import { useState } from "react";
import config from "./Env";
import variables from "./Variables";
import { toast } from "react-toastify";

const ApiService = () => {
  const httpHeaderConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const postData = async (pathname, payload) => {
    try {
      toast.dismiss();
      const { data } = await axios.post(
        `${config.apiBaseUrl}${pathname}`,
        payload,
        {}
      );
      return data;
    } catch (error) {
      toast.dismiss();
      toast.error(variables.someErrorOccurred);
      return error;
    }
  };

  const postDataWithoutAlert = async (pathname, payload) => {
    try {
      toast.dismiss();
      const { data } = await axios.post(
        `${config.apiBaseUrl}${pathname}`,
        payload,
        {}
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const getList = async (pathname) => {
    try {
      toast.dismiss();
      const { data } = await axios.get(`${config.apiBaseUrl}${pathname}`);
      return data;
    } catch (error) {
      return error;
    }
  };




  const ipInfo = async () => {
    try {
      const { data } = await axios.get(`https://ipapi.co/json/`);
      return data;
    } catch (error) {
      return error;
    }
  };


    const postDataNew = async (pathname, payload) => {
      try {
        toast.dismiss();
        const { data } = await axios.post(
          `${config.apiBaseUrl1}${pathname}`,
          payload,
          {}
        );
        return data;
      } catch (error) {
        toast.dismiss();
        toast.error(variables.someErrorOccurred);
        return error;
      }
    };

  return {
    postData,
    getList,
    postDataWithoutAlert,
    ipInfo,
    postDataNew,
  };
};

export default ApiService;
