let variables = {
  copyrightName: "OtoBix Site Portal",
  passwordPatter:
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*\_\-])[a-zA-Z0-9!@#$%^&*\_\-]{8,}$/,
  emailValidationRegex:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  StringValidationRegex: /^[A-Za-z ]+$/,
  StringNumberValidationRegex: /^[a-zA-Z0-9 ]+$/,
  phoneNumberPattern: /[^0-9 ]/g,
  someErrorOccurred: "Oops! Some error occurred. Please try after sometime",
  noDataMessage: "No data found",
  enquirySubmitSuccess: "Your enquiry has been submitted successfully",
  enquirySubmitError: "Unable to submit at this moment",
  leadSubmitSuccess: "Your lead has been submitted successfully",
  pageData: {
    meta_title: "Best Place to Sell a Car | Digital Auction Platform",
    meta_description:
      "Should you want to sell your car, get in touch with our team at OtoBix. We take pride in providing the best services to help our customers buy and sell car.",
    meta_keywords: "OtoBix - The Best Place to Sell Your Car",
  },
};

export default Object.freeze(Object.assign({}, variables));
