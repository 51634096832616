import React from "react";

function Loader() {
  return (
    <div className="shimarbx">
      <div className="shimarcard">
        <div className="loader-shimmer-banner shimmer-animation"></div>
        <div className="loader-shimmer-content">
          <div className="loader-shimmer-header">
            <div className="loader-shimmer-title shimmer-animation"></div>
          </div>
          <div className="loader-shimmer-list shimmer-animation"></div>
          <div className="loader-shimmer-info shimmer-animation"></div>
        </div>
      </div>
      <div className="shimarcard">
        <div className="loader-shimmer-banner shimmer-animation"></div>
        <div className="loader-shimmer-content">
          <div className="loader-shimmer-header">
            <div className="loader-shimmer-title shimmer-animation"></div>
          </div>
          <div className="loader-shimmer-list shimmer-animation"></div>
          <div className="loader-shimmer-info shimmer-animation"></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
