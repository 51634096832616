//Beta API
let config = {
  // Live Server
  apiBaseUrl: "https://otobix.in/admin/api/",
  assetsBaseUrl: "https://otobix.in/admin/public/",
  sitePortalBaseUrl: "https://otobix.in",
  // apiBaseUrl1: "https://integration.automovill.com/integration",
  apiBaseUrl1: "https://developer-beta.com/p5/otobix/api/",
};

export default Object.freeze(Object.assign({}, config));
