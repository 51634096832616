import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/css/source.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import ApiService from "./constants/ApiService";
import Loader from "./partials/Loader";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import ThankYou from "./views/ThankYou/ThankYou";
import { useNavigate, useLocation } from "react-router-dom";

import { CityProvider } from "./constants/CityProvider";

//import file by lazy load
const About = React.lazy(() => import("./views/About/About"));
const FAQ = React.lazy(() => import("./views/FAQ/FAQ"));
const Contact = React.lazy(() => import("./views/Contact/Contact"));
const Home = React.lazy(() => import("./views/HomePage/Home"));
const TermsConditions = React.lazy(() =>
  import("./views/TermsConditions/TermsConditions")
);
const PrivacyPolicy = React.lazy(() =>
  import("./views/PrivacyPolicy/PrivacyPolicy")
);
const InventoryCustomer = React.lazy(() =>
  import("./views/Inventory/InventoryCustomer")
);
const InventoryCarDealers = React.lazy(() =>
  import("./views/Inventory/InventoryCarDealers")
);
const InventoryDetails = React.lazy(() =>
  import("./views/Inventory/InventoryDetails")
);
const BuyNewCar = React.lazy(() => import("./views/BuyNewCar/BuyNewCar"));
const BuyCar = React.lazy(() => import("./views/BuyCar/BuyCar"));
const NewCarFinance = React.lazy(() =>
  import("./views/NewCarFinance/NewCarFinance")
);
const LoanAgainst = React.lazy(() => import("./views/LoanAgainst/LoanAgainst"));
const PreownedCarFinance = React.lazy(() =>
  import("./views/PreownedCarFinance/PreownedCarFinance")
);
const WealthManagement = React.lazy(() =>
  import("./views/WealthManagement/WealthManagement")
);
const SellCar = React.lazy(() => import("./views/SellCar/SellCar"));
const Insurance = React.lazy(() => import("./views/Insurance/Insurance"));
const CarServices = React.lazy(() => import("./views/CarServices/CarServices"));
const CarServicesRepair = React.lazy(() => import("./views/CarServices/CarRepair"));
const CarServicesRSA = React.lazy(() =>
  import("./views/CarServices/CarRSA")
);

const CarWarranty = React.lazy(() => import("./views/CarServices/CarWarranty"));

const CarServicePrice = React.lazy(() => import("./views/CarServices/CarServicePrice"));

const CarServiceStatus = React.lazy(() =>
  import("./views/CarServices/CarServiceBookingStatus")
);

const Events = React.lazy(() => import("./views/ManageMedia/Events"));
const Gallery = React.lazy(() => import("./views/ManageMedia/Gallery"));


function App() {
  //declare service
  const { postDataWithoutAlert } = ApiService();

  //declare state
  let [isLoading, setIsloading] = useState(true);
  let [siteInfo, setSiteInfo] = useState({});

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
    getSiteInfo();
  }, []);

  //get site settings info
  let getSiteInfo = async () => {
    let dataInfo = await postDataWithoutAlert("site-setting-info", {});
    if (dataInfo && dataInfo.responseCode == 200) {
      setSiteInfo(dataInfo.responseData);
    }
  };

  //declare routeshttps://developer-beta.com/otobix/site-portal/
  return (
    <div className="App" id="mainPage">
      <BrowserRouter basename="/">
        <CityProvider>
          <Header siteData={siteInfo} isShowLoader={setIsloading} />
          {isLoading ? <Loader /> : <></>}
          <div className="mainbodyarea">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <React.Suspense fallback={<></>}>
                    <Home isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/about-us"
                element={
                  <React.Suspense fallback={<></>}>
                    <About isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/faqs"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <FAQ isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/contact-us"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <Contact isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/inventory-for-car-dealers"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <InventoryCarDealers isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/inventory-for-customers"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <InventoryCustomer isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/inventory-details/:id"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <InventoryDetails isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <TermsConditions isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/privacy-policy"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <PrivacyPolicy isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/lead-generation"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <BuyNewCar isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/buy-a-car"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <BuyCar isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/sell-a-car"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <SellCar isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/new-car-finance"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <NewCarFinance isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/loan-against-car"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LoanAgainst isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/pre-owned-car-finance"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <PreownedCarFinance isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/insurance"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Insurance isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/car-services"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarServices isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/car-repair"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarServicesRepair isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/road-side-assistance"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarServicesRSA isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path="/car-service-price"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarServicePrice isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path="/warranty"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarWarranty isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path="/car-service-booking-status"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <CarServiceStatus isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/wealth-management-services"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <WealthManagement isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/thank-you"
                element={<ThankYou isShowLoader={setIsloading} />}
              />

              <Route
                exact
                path="/events"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <Events isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/gallery"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <Gallery isShowLoader={setIsloading} />
                  </React.Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Navigate to="/" />
                  </React.Suspense>
                }
              />
            </Routes>
          </div>

          <div>
            <Footer siteData={siteInfo} isShowLoader={setIsloading} />
          </div>
        </CityProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
